.container {
  position: relative;
  height: 200px; /* Adjust height as needed */
  width: 300px;  /* Adjust width as needed */
  overflow: hidden;
  flex-grow: 1;
  margin-left: 10px;
  height: 240px;
}

.step {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 1s ease, transform 1s ease;
  text-align: center;
}

.step .label {
  font-size: 20px; /* Adjust font size as needed */
  margin-bottom: 8px; /* Space between label and content */
  text-align: center;
  border-radius: 1000px;
  outline: 2px solid black;
  aspect-ratio: 1 / 1;
  height: 30px;
  width: 30px;
  font-weight: 600;
}

.step .content {
  font-size: 16px; /* Adjust font size as needed */
  margin-bottom: 8px; /* Space between content and image */
}

.step .step-image {
  height: auto;
  margin-top: 8px; /* Space between content and image */
}

.step.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.step.fade-out {
  opacity: 0;
  transform: translateY(100%);
}

/* Ensure the new step starts offscreen from the top */
.step:nth-of-type(2) {
  transform: translateY(-100%);
}

.step.fade-in {
  animation: fadeInUp 1s forwards;
}

.step.fade-out {
  animation: fadeOutDown 1s forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}
